body {
  margin: 0;
  font-family: Verdana, Geneva, sans-serif;
  background-color:#151412;
  color:white; 
} 

.navbar{
  background-color:#f0bd26;
  color:#151412;
}

.navbarLinks{
  color:#151412;
  font-size: large;
}
.navbarLinks:hover{
  text-decoration: underline;
  text-decoration-thickness: 0.10rem;
}

.list{
  font-size: 1.25rem;
}

.updates_date{
  font-size: 1.20rem;
  text-decoration: underline;
}

.description{
 text-align: justify;
}

/* style de présentation des projets */
.project_title{
  color:#f1f4f7;
  margin-top:2rem;
}

.project_subtitle{
  color:#cdcfce;
  margin-top:0.5rem
}

.infos{
  color:#dee2e6;
  margin:0.5rem 0 1.5rem 0rem;
  
}

.infos_solo{
  padding: 0 0 0.25rem 0;
}

.accordion{
  margin:1rem 0 0 0;
  
}

.project_title{
  margin-top:2rem;
}

#ada{
  color:#f0bd26
}

#link{
  color:#d3a829; 
  text-decoration: underline;
  text-decoration-color:#d3a829;
}

#link:hover{
  color:rgb(205, 84, 195);
  text-decoration: underline;
  text-decoration-color: rgb(205, 84, 195);
}

.intro{
  color:#d3a829;
}

.card_img{
  border-radius: 4px;
}

.card_style{
  color:#151412;
  border-radius: 4px;
  border-width:2px;
  border-color:#dee2e6;
  background-color:white;
}

.button{
  color:#151412;
  border-radius:4px;
  border-width:2px;
  border-color: #f0bd26;
  background-color:white;
}

.button:hover{
  border-color:#f0bd26;
  background-color:#f0bd26;
  color:#151412
}

.badge1{
  color:#dee2e6;
  background-color:lightseagreen;
}

.contact_infos{
  color:#5e5d5c;
}

.accordion{
  background-color: #151412 !important;
  color:#f1f4f7;
  border-color: #151412;
}

